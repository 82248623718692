<template>
  <div class="row">
    <div class="col-md-2">
      <div id="nav">
        <span style="background-color: lightgreen;"><b>Pakiety sprzedażowe</b><br/></span>
        <hr>
        <router-link to="/admin/sales-package/list">Przeglądaj pakiety sprzedażowe</router-link><br/><br/>
        <router-link to="/admin/sales-package/new">Stwórz pakiet sprzedażowy</router-link><br/><br/>
        <router-link to="/admin/sales-package/code/check">Sprawdź kod</router-link>

        <br/><br/>
        <span style="background-color: lightgreen;"><b>Partnerzy</b><br/></span>
        <hr>
        <router-link to="/admin/partner/list">Przeglądaj partnerów</router-link><br/><br/>
        <router-link to="/admin/partner/new">Stwórz partnera</router-link>

        <br/><br/>
        <span style="background-color: lightgreen;"><b>Płatności</b><br/></span>
        <hr>
        <router-link to="/admin/payment/list">Przeglądaj płatności</router-link><br/><br/>

        <br/><br/>
        <span style="background-color: lightgreen;"><b>Użytkownicy</b><br/></span>
        <hr>
        <router-link to="/admin/user/list">Przeglądaj użytkowników</router-link><br/><br/>
        <router-link to="/admin/user/new">Dodaj użytkownika</router-link><br/><br/>
        <router-link to="/admin/user/users">Dodaj wielu użytkowników</router-link><br/><br/>
      </div>
    </div>
    <div class="col-md-10">
      <router-view/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Admin'
};
</script>

<style scoped>

</style>
